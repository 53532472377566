.events {
    width: 100%;
    height: 80vh;
}

.events-section-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 40px;
}

.events-section-2 img {
      border-radius: 20px;
      
  }

.events-section-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    
  }

  .events-wrapper{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
  }

.events-image-1 img{
  margin-left: 20px;
  margin-top: 100px;
  /* padding-left: 20px;
  border-radius: 30%; */
}

.events-page-heading {
    font-size: 70px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .events-page-interaction {
    margin: 0 auto;
    margin-top: 100px;
  }

  .events-section-1 p {
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .what-we-offer ul li{
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    .events-wrapper{
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
      }

      .events-section-1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        
      }

      .events-page-heading {
        font-size: 40px;
        font-family: 'Roboto', sans-serif; 
        font-weight: bolder;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    
      }

      .events-section-2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .events-section-2 img {
        width: 100%;
    }
}

