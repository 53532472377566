.cookie-permission {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid red;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    max-height: 25px;
  }


  .permission-button {
    border: 1px solid green;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    color: black;
    width: auto;
    height: 20px;
    cursor: pointer;
  
  }

.cookie-permission a:hover {
    color: blue;
}