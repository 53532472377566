.console-gaming {
    width: 100%;
}

.console-gaming-section-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 40px;
}

.console-gaming-section-2 img {
      border-radius: 20px;
      
  }

.console-gaming-section-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    
  }

  .gaming-page-wrapper{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

  .gaming-page-wrapper-2{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

.second-image img{
  margin-top: 100px;
  margin-left: 20px;
  margin-bottom: 40px;
  padding-left: 20px;
  border-radius: 30%;
}

.gaming-page-heading {
  font-size: 70px;
  font-family: 'Roboto', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.console-gaming-section-1 p {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.gaming-page-interaction {
  margin: 0 auto;
  margin-top: 100px;
}

.what-we-offer ul li{
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-left: 40px;
  margin-right: 40px;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {

  body {
    width: 100%;
    height: auto;

  }

  .gaming-page-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }

  .gaming-page-wrapper-2{
    display: flex;
    width: 100%;
    flex-direction: column;
    
  }

  .console-gaming-section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    align-items: center;
    
  }

  .console-gaming-section-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    align-items: center;
    
  }

  .gaming-page-heading {
    font-size: 40px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }

  .console-gaming-section-2 img {
    border-radius: 20px;
    width: 95%;
    
}

.second-image img{
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  border-radius: 30%;
  width: 90%;
}

}
