
ul {
    padding-left: 0;
    text-align: center;
  }
  
  li {
    list-style-type: none;
    padding: 10px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif; 
    font-size: 20px;
  }

.navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;
    /* -webkit-box-shadow: 0 0 10px #ccc; */
    /* box-shadow: 0 0 10px #ccc; */
    
  }

.navbar ul {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    text-decoration: none;
    align-items: center;
    margin: 0px;
  }

  .navbar li {
    font-size: 20px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    background-color: white;
    color: red;
    
  }

  .navbar li:hover {
    background-color: red;
    color: white;
    border-radius: 5px;
    
  }

  .company-title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .logocontainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 80px;
      margin-top: 60px;
      /* newly added */
      width: 10%;
      height: 100%;
      object-fit: cover;
  }

  .navbar img {
    width: 160px;
    height: auto;
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 100px;
    object-fit: cover;
    max-width: 160px;
  }

  .profile-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    color: skyblue;
  }

  .profile-data img {
    width: 50px;
    height: auto;
    margin-left: 2px;
    border-radius: 100px;
    object-fit: cover;
    max-width: 160px;
  }

  .logo {
      border: 2px solid black;
      padding: 15px;
      margin-left: 20px;
      font-size: 50px;
      font-family: 'Roboto', sans-serif; 
      font-weight: bolder;
      border-radius: 50%;
      border-color: black black #ea0e37 #ea0e37;
      transform: rotate(45deg);
  }

  .logo > div {
      transform: rotate(-45deg);
  }

  .logoname {
    font-size: 40px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin-left: -80px;
  }

  .fstyle {
      color: #ea0e37;
  }

  .resp-menu {
    display: none;
  }

  .menu {
    margin-right: 5px;
  }

  .nav-items {
    display: none;
    position: absolute;
    font-family: 'Varela Round', sans-serif;
    background-color: white;
    margin-top: 10px;
    margin-left: -50px;
    /* z-index: 1; */
    /* border-radius: 0 0 10px 10px; */
    /* -webkit-box-shadow: 0 0 10px #ccc; */
    /* box-shadow: 0 0 10px #ccc; */
  }

  .menu li:nth-child(2):hover .nav-items{
  display: flex;
  flex-direction: column;
  
}
.nav-items a {
  padding: 15px;
}

.nav-items a:hover {
  background-color: red;
}

  button {
    padding: 10px;
    border: none;
    font-family: 'Roboto', sans-serif; 
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 80px;
    background-color: #ea0e37;
    color: white;
    margin: 5px;
  }

  @media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }
  
    .resp-menu {
      display: flex;
      width: 18%;
      height: auto;
      margin-right: 10px;
      justify-content: end;
    }
  
    .menu {
      display: none;
      flex-direction: column;
      width: 100%;
      height: auto;
      position: absolute;
      top: 10%;
      background-color: white;
      padding: 20px;
  
    }

    .menu ul {
      flex-direction: column;
    }

    .company-title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .logoname {
      font-size: 20px;
      font-family: 'Roboto Condensed', sans-serif; 
      font-weight: bolder;
      margin-left: -63px;
      margin-bottom: 3px;
    }

    .navbar {
      overflow-y: scroll;
    }

    .menu {
      overflow: scroll;
    }

    .nav-items {
      overflow: scroll;
      margin-left: -25px;
    }

    .navbar img {
      width: 100px;
      height: auto;
      border-radius: 100px;
      margin-right: 20px;
      max-width: 130px;

    }
    .nav-items a {
      padding: 5px;
      font-size: 15px;
    }

    .profile-data img {
      width: 50px;
      height: auto;
      margin-left: 2px;
      border-radius: 100px;
      object-fit: cover;
      max-width: 160px;
    }

  }
