.shooting-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    margin: 20px;
}

.shooting-page-wrapper{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

.shooting-page-wrapper-2{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

.shooting-page-section-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 50%;
    
  }

.shooting-page-section-2-gun {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 50%;
    margin-top: 200px;
    
  }

.gunstyle  {
  /* transform: rotate(-90deg); */
  /* width: 500px;
  margin-left: 300px; */
}

.shooting-page-section-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    
  }

  .shooting-page-heading {
    font-size: 70px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 20%;
    display: flex;
    align-items: center;

  }

  .fstyle {
    color: #ea0e37;
}

.shooting-page-interaction {
  margin: 0 auto;
  margin-top: 40px;
}

.what-we-offer ul li{
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-left: 40px;
  margin-right: 40px;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {

  body {
    width: 100%;
    height: auto;

  }

  .shooting-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

  .shooting-page-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    padding: 0px;
    margin: 0px;
    margin-top: 40px;
  }

  .shooting-page-wrapper-2{
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .shooting-page-heading {
    font-size: 40px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    display: flex;

  }

  .what-we-offer ul li{
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
  }

  .shooting-page-section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0px;
    
  }
  .shooting-page-section-2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .shooting-page-section-2-gun{
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-top: 10px;
  }

  .shooting-page-section-2 img{
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .shooting-page-section-2-gun img{
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .shooting-page-section-1 img{
    display: flex;
    flex-direction: column;
    width: 90%;
    
  }


  
}