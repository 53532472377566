.images-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    /* height: fit-content; */
    margin: 0 auto;
    margin-top: 80px;
    flex-wrap: wrap;
    height: 80vh;

}

/* .images-container img {
    width: 110px;
    height: 140px;
    border-radius: 50%;
    margin: 5px;
} */

.slider-images {
    margin: 0 auto;
}

.image-thumbs {
    display: flex;
    flex-direction: row;
    width: fit-content;
    /* height: fit-content; */
    margin: 0 auto;
}

.image-thumbs img {
    height: 50px;
    width: 50px;
    margin: 5px;
    cursor: pointer;
}

.ismedia {
    display: none;
}

@media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    .images-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        height: fit-content;
        margin-bottom: 80px;
        align-items: center;
    }

    .slider{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        height: fit-content;
        align-items: center;
        z-index: -1;
    }


    .image-thumbs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        justify-content: center;
    }


}