.privacy-ul {
    padding-left: 0;
    text-align: left;
  }
  
  .privacy-li {
    list-style: square inside;
    display: list-item;
    margin-left: 1em;
    font-family: 'Roboto Normal', sans-serif; 
    font-size: 15px;
  }

.privacy {
    margin-left: 10px;
    margin-top: 40px;
}

.privacy a:hover {
    color: blue;
}