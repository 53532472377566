.footer {
    width: 100%;
    background-color: #3E8EB3;
    color: white;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.footer-text {
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cnumber {
    color: white;
}

.cemail {
    color: white;
}

@media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    .footer {
        display:flex;
        flex-direction: column;
    }

    .footer-text {
        padding: 10px;
    }
    
}