body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F5F6F7; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

input {
  border: 1px solid #1a73e8;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  height: 20px;
}

input:focus {
  outline: 0;
}

textarea {
  border: 1px solid #1a73e8;
  border-radius: 10px;
  margin: 10px;
  height: 80px;
  padding: 10px;
}

textarea:focus {
  outline: 0;
}

button {
  padding: 10px;
  border: none;
  font-family: 'Roboto', sans-serif; 
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 80px;
  background-color: #ea0e37;
  color: white;
  margin: 5px;
}

button:focus {
  outline: 0;
}

button:disabled{
  background-color: #ccc;
}

button:hover {
  -webkit-box-shadow: 0 0 10px #ea0e37;
  box-shadow: 0 0 10px #ea0e37;
}

button:active {
  background-color: green;
}
