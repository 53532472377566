.snooker {
    width: 100%;
}

.snooker-section-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 40px;
}

.snooker-section-2 img {
      border-radius: 20px;
      
  }

.snooker-section-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    
  }

  .snooker-wrapper{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

  .snooker-wrapper-2{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

.second-image-1 img{
  margin-left: 20px;
  margin-top: 100px;
  /* padding-left: 20px;
  border-radius: 30%; */
}

.snooker-page-heading {
    font-size: 70px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .snooker-page-interaction {
    margin: 0 auto;
    margin-top: 100px;
  }

  .snooker-section-1 p {
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .what-we-offer ul li{
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    
    .snooker-wrapper{
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
    }
    
    .snooker-wrapper-2{
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .snooker-section-2 {
      display: flex;
      flex-direction: column;
      width: 100%;
  }

  .snooker-section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }

  .snooker-page-heading {
    font-size: 40px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .snooker-section-2 img {
    width: 100%;
    margin: 0px;
  }

  .snooker-section-1 img {
    width: 100%;
    margin: 0px;
  }


  }
