.contact {
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 50px;
  flex-direction: column;
  align-items: center;
}

.contact-us {
    display: flex;
    flex-direction: column;
   
}

.contact-section {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
.contact-section-2 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .contact-info, .contact-info-2 {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    width: 50%;
    max-width: 50%;
    
  }

  .maps {
    width: 100%;
  }

  .contact-info-2 {
  }

  .heading {
    margin-top: 20px;
    font-size: 40px;
    font-family: 'Varela Round', sans-serif;
  }

  .contact-details {
    flex-direction: column;
  }

  .heading-3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 30px;
  }

  .message-recorded{
    border: 2px solid green;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .progress-bar {
    margin-top: 10px;
  }


  .ccnumber:hover {
    color: black;
}

.ccemail:hover {
    color: black;
}
  @media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    .contact-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .contact-section-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-direction: column-reverse;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      /* border: 1px solid #ccc; */
      border-radius: 20px;
      padding: 0px;
      margin: 5px;
      width: 100%;
      max-width: 100%;
      
    }

    .contact-info-2 {
      display: flex;
      flex-direction: column;
      /* border: 1px solid #ccc; */
      border-radius: 20px;
      padding: 0px;
      margin: 5px;
      width: 100%;
      max-width: 100%;
      
    }

    .c-title {
      text-align: center;
    }

    .contact-info img {
      width: 100%;
    }

    .heading {
      margin-top: 0px;
      font-size: 30px;
      font-family: 'Varela Round', sans-serif;
      text-align: center;
    }

    .contact-details {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .address {
      margin-left: 10px;
    }

    .message-recorded{
      border: 2px solid green;
      width: 90%;
      padding: 10px;
      border-radius: 20px;
      margin-left: 5px;
    }

    .contact-info p{
      text-align: center;
    }
  }