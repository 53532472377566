.console-home {
    width: 100%;
}

.console-home-section-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 40px;
}

.console-home-section-2 img {
      border-radius: 20px;
      
  }

.console-home-section-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    
  }

  .home-page-wrapper{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

  .home-page-wrapper-2{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: column;
    margin-top: 20px;
  }

  .home-page-wrapper-3{
    display: flex;
    width: 100%;
    /* height: 100vh; */
    flex-direction: row;
    margin-top: 20px;
  }

.second-image img{
  margin-top: 100px;
  margin-left: 20px;
  margin-bottom: 40px;
  padding-left: 20px;
  border-radius: 30%;
}

.homecard {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    justify-content: space-around;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    margin-left: 20px;
    box-shadow: 0 0 10px 10px #ccc;
    border-radius: 10px;
}

.card-text {
    margin: 10px;
}


.card img {
    height: 300px;
    width: 400px;
    border-radius: 20px;
}

.card a {
    margin-top: 20px; 
    margin-bottom: 20px; 
    

}

.card-heading {
    font-size: 30px;
  font-family: 'Roboto', sans-serif; 
  font-weight: bolder;
  margin: 10px;
}

.home-page-heading {
  font-size: 70px;
  font-family: 'Roboto', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.console-home-section-1 p {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.home-page-interaction {
  margin: 0 auto;
  margin-top: 100px;
}

.what-we-offer ul li{
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif; 
  font-weight: bolder;
  margin: 0 auto;
  margin-left: 40px;
  margin-right: 40px;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {

  body {
    width: 100%;
    height: auto;

  }

  .home-page-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }

  .home-page-wrapper-2{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 0px;
    
  }
  .home-page-wrapper-3{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 0px;
    
  }

  .console-home-section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    align-items: center;
    
  }

  .console-home-section-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    align-items: center;
    
  }

  .home-page-heading {
    font-size: 40px;
    font-family: 'Roboto', sans-serif; 
    font-weight: bolder;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }

  .console-home-section-2 img {
    border-radius: 20px;
    width: 95%;
    
}

.homecard{
    width: 100%;
    flex-direction: column;
    margin: 0px;
    margin-bottom: 40px;
    /* width: 100%; */
}

.card {
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 auto;
    margin-top: 40px;
    width: 300px;
}

.card img {
    width: 350px;
}

.card-heading{
    margin-top: 0px;
}

.second-image img{
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  border-radius: 30%;
  width: 90%;
}

}
